import React from 'react';

import Layout from '@common/Layout';
import { Container } from '@components/global';

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1> Página Não Encontrada </h1>
      <p> Você tentou acessar uma página que não existe. </p>
    </Container>
  </Layout>
);

export default NotFoundPage;
